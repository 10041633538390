@import (reference) "@/client/assets/vars.less";

#layout.dashboard {

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    min-height: 100vh;
    gap: 0;

    .center {
        max-width: @layoutWidth;
        width: 100%;
        margin: 0 auto;
    }

    /*----------------------------------
    - LAYOUT
    ----------------------------------*/

    #sidebar #identity,
    #page > header {
        height: 5em;
    }

    #sidebar,
    #page > header {
        position: sticky;
        top: 0;
        z-index: 5;
    }

    #sidebar {
        flex: 0 0 @navigSidebarWidth;
        width: @navigSidebarWidth; // For mobile mode = absolute display
        border-right: @layoutLine;

        height: 100vh;
        padding: 0.5em 1em;
        gap: 0;

        #identity {
            // Align with the space top menu
            background: url(../../assets/identity/logoAndTextBlack.svg) left 1.5em center no-repeat;
            background-size: 120px auto;
        }

        > nav {

            overflow: auto;

            /*> .menu + *::before {
                content: ' ';
                display: block;
                margin: @spacing @spacing * 2;
                border-top: @layoutLine;
            }*/

            > strong {
                display: block;
                text-align: left;
                color: var(--cTxtDesc);
                padding: @spacing;
                font-weight: inherit;
                font-size: 0.8em;
            }

            > .menu {
                margin-bottom: @spacing;
            }
        }
    }   
    
    #page {
        flex: 1;
        gap: 0;
        min-height: 100vh;

        > header {
            border-bottom: @layoutLine;

            h1 {
                font-size: 1.2rem;

                > i {
                    margin-right: @spacing / 2;
                    color: var(--cTxtDesc);
                    font-size: 1.2rem;
                }
            }
        }

        > header,
        > main {

            > * {
                padding: @spacing @spacing * 2;
            }
        }

        > main {
            //padding-bottom: 20em;

            > header {
                border-radius: 0;
            }
        }
    }

    #user {
        flex: 0 0 @userSidebarWidth;
        width: @userSidebarWidth; // For mobile mode = absolute display
    }

    .page {

        display: flex;
        flex-direction: column;
        gap: 2em;
        //padding-bottom: 15vh;

        > * {
            + * {}
        }
    }

    /*----------------------------------
    - COMPONENT
    ----------------------------------*/

    h2 {
        text-align: left;
        //color: var(--cTxtDesc);
    }
}

@import "./mobile.less";

#loading {
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: fade(#fff, 80%);
    //backdrop-filter: blur(10px);
    z-index: 998;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    opacity: 0;
    visibility: hidden;
    transition: opacity 5s ease-out;

    &.display {
        opacity: 1;
        visibility: visible
    }
}

#bottomView {
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: fade(#000, 10%);
    position: sticky;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 2;

    // Fadein background opacity to animation
    animation: openBottomView 0.5s ease-out;

    > .bg.white {
        padding: 0!important;
        height: 50vh;
        min-height: 350px;
        max-height: 90vh;
        border-top: @layoutLine;
        animation: openBottomViewPanel 0.5s ease-out;
    
        > * {
            overflow: auto;
            justify-content: flex-start;
            padding: @spacing @spacing * 2;
        }
    }
}

@keyframes openBottomView {
    from {
        background: fade(#000, 0%);
    }
    to {
        background: fade(#000, 10%);
    }
}

@keyframes openBottomViewPanel {
    from {
        transform: translateY(80%);
    }
    to {
        transform: translateY(0%);
    }
}

/*----------------------------------
- COMPONENTS
----------------------------------*/

.splitview  {
    padding: 0!important;
    //height: 50vh;
    min-height: 700px;
    max-height: 70vh;
}

.kanban {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: @spacing * 2;

    overflow: auto;

    > div {
        flex: 0 0 320px;
        text-align: left;

        .Items {
            max-height: 500px; // TODO: max height = screen
            overflow: scroll;
            justify-content: flex-start;
        }

        .Items > * {
            &.active {
                box-shadow: 0 0 0 2px @c1, 0 0 20px fade(@c2, 40%);
                border-radius: @radius;
                z-index: 2;
            }
        }
    }
}

.kpi {
    display: flex;
    gap: @spacing;

    > .number {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: @spacing / 2;

        > strong {
            font-size: 1.3em;
        }
    }
}

.UpgradeBlur {

    position: relative;

    .Blur {

        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 10;

        backdrop-filter: blur(10px);
        background-color: fade(#fff, 10%);
    }

    .table {
        pointer-events: none;
    }
}


@import 'react-notion/src/styles.css';