@import (reference) "@/client/assets/vars.less";

#layout.dashboard {

    // Minimize sidebar
    @media only screen and (max-width: 1100px) and (min-width: 600px) {

        #sidebar {
            flex: 0 0 7em;

            #identity > img + div {
                display: none;
            }

            .menu a .label {
                display: none;
            }

            .card.bg.blur {
                display: none;
            }
        }
    }

    // Hide mobile display buttons when no mobile mode
    @media only screen and (min-width: 900px) {
        #mobileBtns {
            visibility: hidden;
        }
    }

    // Enter mobile mode: mobile buttons + slideable page
    @media only screen and (max-width: 900px) {

        #page {
            z-index: 1;
            background: #F5F5F5;
            transition: transform .2s ease-out;
        }

        &.userMenuOpen,
        &.navigMenuOpen {
            #page {
                box-shadow: 0 0 40px fade(#000, 20%);
                
                &::after {
                    content: ' ';
                    background: fade(#F5F5F5, 50%);
                    backdrop-filter: blur(10px);
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                }
            }
        }
    }

    // Autohide user sidebar
    @media only screen and (max-width: 900px) {
                
        #user {
            position: fixed;
            z-index: 0;
            top: 0;
            bottom: 0;
            right: 0;
            overflow: auto;
            opacity: 0;
            transition: opacity .2s linear;
        }

        &.userMenuOpen {

            #page {
                @userMenuOpenGap: @userSidebarWidth + 2em;
                transform: ~"translateX(-@{userMenuOpenGap})";
            }

            #user {
                opacity: 1;
            }
        }
    }

    // Autohide navig sidebar
    @media only screen and (max-width: 600px) {
                
        #sidebar {
            position: fixed;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            opacity: 0;
            transition: opacity .2s linear;
        }

        &.navigMenuOpen {
            #page {
                @navigMenuOpenGap: @navigSidebarWidth + 2em;
                transform: ~"translateX(@{navigMenuOpenGap})";
            }

            #sidebar {
                opacity: 1;
            }
        }

    }
}