@import (reference) "@/client/assets/vars.less";

#layout.employer {

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3em;
    padding: 10vh 2vh;

    min-height: 100vh;

    .center {
        max-width: @layoutWidth;
        width: 100%;
        margin: 0 auto;
    }

    /*----------------------------------
    - LAYOUT
    ----------------------------------*/

}