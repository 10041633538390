/*----------------------------------
- GLOBAL THEMES
----------------------------------*/

@import './fonts/Inter/index.less';
@import './fonts/Rubik/index.less';

::selection {
    background: @cDark;
    color: #fff;
}

h1,
h2 {
    font-family: "Rubik";
}

/*----------------------------------
- BASIC THEMES
----------------------------------*/

@whiteTheme: {
    alpha: false;
    background: #FFF;
    foreground: #444;
    accent1: #6C5DD3;
    accent2: @c2;
}

@greyTheme: {
    alpha: false;
    background: #F8F8F8;
    foreground: #8E8E8E;
    accent1: @c1;
    accent2: @c2;
}

@silverTheme: {
    alpha: false;
    background: #F5F5F5;
    foreground: #777;
    accent1: @c1;
    accent2: @c2;
}

@darkTheme: {
    alpha: false;
    background: #050505;
    foreground: #999;
    accent1: #E17DB2;
    accent2: @c2;
}

@darkerTheme: {
    alpha: false;
    background: #181818;
    foreground: #bbb;
    accent1: #fff;
    accent2: @c2;
}

@darkestTheme: {
    alpha: false;
    background: #242424;
    foreground: #ddd;
    accent1: #fff;
    accent2: @c2;
}

body,
.bg.grey {
    .apply-theme(@greyTheme, @whiteTheme);
}

.card,
.bg.white {
    .apply-theme(@whiteTheme, @greyTheme);
}

.card {
    box-shadow: 0 3px 2px fade(#000, 10%);
    border: 1px solid fade(#000, 10%);

    &.floating {
        box-shadow: 0 10px 50px fade(black, 15%);
        z-index: 10;
    }

    &.minimal {
        box-shadow: none;
    }
}

.bg.silver {
    .apply-theme(@silverTheme, @whiteTheme);

    box-shadow: inset 0 0 20px fade(#000, 5%)
}

.bg.dark {
    .apply-theme(@darkTheme, @darkerTheme);
}

.bg.darker {
    .apply-theme(@darkerTheme, @darkestTheme);
}

/*----------------------------------
- COLOR THEMES
----------------------------------*/

@primaryTheme: {
    alpha: false;
    background: #111;
    foreground: #fff;
    accent1: #FFF;
    accent2: #FFF;
}

@secondaryTheme: {
    alpha: false;
    background: #F5F5F5;
    foreground: #8E8E8E;
    accent1: @c1;
    accent2: @c2;
}

@accentTheme: {
    alpha: false;
    background: #EAE8F9;
    foreground: @c1 - #444;
    accent1: #fff;
    accent2: #fff;;
}

.bg.primary {

    .apply-theme(@primaryTheme);
    
    &.btn:hover {
        
    }
}

.bg.secondary {
    .apply-theme(@secondaryTheme); 
}

.bg.accent {
    .apply-theme(@accentTheme); 
}

.color-theme( @color ) {
    .apply-theme({ 
        alpha: false;
        background: @color; 
        foreground: #111;
        accent1: #111;
        accent2: #111;
    });
}

.bg.light1 { .color-theme(#cce0fe ); }
.bg.light2 { .color-theme(#c7f0ee ); }
.bg.light3 { .color-theme(#cef5d2 ); }
.bg.light4 { .color-theme(#feeab6 ); }
.bg.light5 { .color-theme(#ffdccc ); }
.bg.light6 { .color-theme(#fcccf0 ); }
.bg.light7 { .color-theme(#e2d5f7 ); }

.bg.success {
    box-shadow: none;
    border: none;

    .apply-theme({ 
        alpha: false;
        background: @cSuccess; 
        foreground: lighten( @cSuccess, 50% );
        accent1: #111;
        accent2: #111;
    });

    &.light {
        .apply-theme({ 
            alpha: false;
            background: lighten( @cSuccess, 40% ); 
            foreground: darken( @cSuccess, 50% );
            accent1: #111;
            accent2: #111;
        });
    }
}

.bg.error {
    box-shadow: none;
    border: none;

    .apply-theme({ 
        alpha: false;
        background: @cError; 
        foreground: lighten( @cError, 50% );
        accent1: #111;
        accent2: #111;
    });

    &.light {
        .apply-theme({ 
            alpha: false;
            background: lighten( @cError, 20% ); 
            foreground: darken( @cError, 50% );
            accent1: #111;
            accent2: #111;
        });
    }
}

.bg.warn { 
    box-shadow: none;
    border: none;

    .apply-theme({ 
        alpha: false;
        background: @cWarn; 
        foreground: lighten( @cWarn, 50% );
        accent1: #111;
        accent2: #111;
    });

    &.light {
        .apply-theme({ 
            alpha: false;
            background: lighten( @cWarn, 20% ); 
            foreground: darken( @cWarn, 50% );
            accent1: #111;
            accent2: #111;
        });
    }
}

.bg.info {
    box-shadow: none;
    border: none;

    .apply-theme({ 
        alpha: false;
        background: @cInfo; 
        foreground: lighten( @cInfo, 50% );
        accent1: #111;
        accent2: #111;
    });

    &.light {
        .apply-theme({ 
            alpha: false;
            background: lighten( @cInfo, 35% ); 
            foreground: darken( @cInfo, 50% );
            accent1: #111;
            accent2: #111;
        });
    }
}

.bg.bronze { .color-theme( #C77C30 ); }
.bg.silver { .color-theme( #AAA ); }
.bg.gold { .color-theme( #FFD700 - #222 ); }

/*----------------------------------
- GRADIENT & IMG THEMES
----------------------------------*/

.bg.img {

    .apply-theme({
        alpha: true;
        background: #000;
        foreground: fade(#fff, 60%);
        accent1: blue;
        accent2: green;
    }, @whiteTheme);

    &.blur {
        background: url("./img/background/header-blur.png") center;
        background-size: cover;
        box-shadow: 0 10px 20px fade(#000, 10%);
    }
}

/*----------------------------------
- FOREGROUND THEMES
----------------------------------*/

// Color themes
.primary.fg { 
    .build-theme-fg(@c1, #fff); 
}

.fg {
    &.success { .build-theme-fg( @cSuccess ); }
    &.error { .build-theme-fg( @cError ); }
    &.warn { .build-theme-fg( @cWarn ); }

    &.bronze { .build-theme-fg( #C77C30); }
    &.silver { .build-theme-fg( #AAA ); }
    &.gold { .build-theme-fg( #FFD700 - #222 ); }

    &.gradient {
        color: #B4C77C;
    }
}