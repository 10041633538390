@import (reference) "@/client/assets/vars.less";

#layout.auth {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5vh;

    padding: 5vh;

    @media screen and (max-width: 500px) {

        padding: 2vh;

        > .card {
            flex-direction: column;

            > .bg.img {
                min-height: 300px;
                border-radius: 0 0 @radius @radius;
            }
        }
    }
}